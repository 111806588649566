<template>
  <section id="home" class="header_area">
    
    <div id="header_navbar" class="header_navbar">
      <div class="container d-flex align-items-center justify-content-between" style="width: 100%">
        <div class="row">
          <div class="col-lg-12">
            <nav class="navbar navbar-expand-lg">
              <a class="navbar-brand" href="/">
                <img id="logo" href="#" width="150px" src="../../public/assets/images/logo_creditall_inverso.png">
              </a>

              <button class="navbar-toggler" type="button"
                data-toggle="collapse" data-target="#navbarSupportedContent" 
                aria-controls="navbarSupportedContent" 
                aria-expanded="false" 
                aria-label="Toggle navigation"
              >
                <span class="toggler-icon"></span>
                <span class="toggler-icon"></span>
                <span class="toggler-icon"></span>
              </button>

              <div class="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                <ul id="navbar-toggler" class="navbar-nav ml-auto">
                  <li class="nav-item"><a style="margin-top:10px;" class="page-scroll" href="#empresa"><div data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">Sobre</div></a></li>
                  <li class="nav-item dropdown"><a class="page-scroll" style="margin-top:10px; display: flex; white-space: nowrap;"><span>Produtos e Serviços</span><span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" style="background: #bf1b39;">Novo<span class="visually-hidden">unread messages</span></span><i class="bi bi-chevron-down position-absolute start-100"></i></a>
                    <ul>
                      <li><a style="font-size: 12px; white-space: nowrap;" class="page-scroll" href="#servicos"><div data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">Garantia de Crédito</div><span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" style="background: #bf1b39;">Novo<span class="visually-hidden">unread messages</span></span></a></li>
                      <li><a style="font-size: 12px; white-space: nowrap;" class="page-scroll" href="#garantiaaluguel"><div data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">Garantia de Aluguel</div><span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" style="background: #bf1b39;">Novo<span class="visually-hidden">unread messages</span></span></a></li>
                      <li><a style="font-size: 12px;" class="page-scroll" href="#plataformacredito"><div data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">Plataforma de Crédito</div></a></li> 
                    </ul>
                  </li>
                  <li class="nav-item"><a style="margin-top:10px;" class="page-scroll" href="#quero"><div data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">Quero ser Cliente</div></a></li>
                  <li class="nav-item"><a style="margin-top:10px;" class="page-scroll" href="/trabalhe-conosco">Trabalhe conosco</a></li>
                  <li class="nav-item dropdown"><a class="page-scroll" style="margin-top:10px; display: flex; white-space: nowrap;"><span>Área do Cliente</span><i class="bi bi-chevron-down position-absolute start-100" style="padding: 0px 0px 40px 0px;"></i></a>
                    <ul>
                      <li><a style="font-size: 12px;" href="/sou-lojista">Sou Lojista</a></li>
                      <li><a style="font-size: 12px;" href="/sou-consumidor">Sou Consumidor</a></li>
                      <li><a style="font-size: 12px;" href="https://www.siscredit.com.br/boletos/extrato_locatario.php">Sou Locatário</a></li>
                    </ul>
                  </li>
                  <li class="nav-item">
                    <div class="d-grid">
                      <a href="https://www.siscredit.com.br" class="btn" 
                        style="font-family: poppins, sans-serif; font-size: 12px; 
                               margin-top: 10px; font-weight: 400; color: #fff; background: #bf1b39; 
                               padding: 9px; right: 1rem; left: 0px; white-space: nowrap; margin-bottom: 15px;
                        border-radius: 8px; text-decoration: none;">
                        Acessar o sistema
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <div class="header_hero"></div>

    <div id="carousel-example-2" data-interval="5500" class="carousel slide carousel-fade" data-ride="carousel">
      <ol class="carousel-indicators">
        <li data-target="#carousel-example-2" data-slide-to="0" class="active"></li>
        <li data-target="#carousel-example-2" data-slide-to="1"></li>
        <li data-target="#carousel-example-2" data-slide-to="2"></li>
        <li data-target="#carousel-example-2" data-slide-to="3"></li>
        <li data-target="#carousel-example-2" data-slide-to="4"></li>
        <li data-target="#carousel-example-2" data-slide-to="5"></li>
      </ol>

      <div class="carousel-inner" role="listbox">
        <div id="bg-cover" class="carousel-item active single_hero" style="background-image: url('assets/images/banners_novo_grafico.png')">
          <div class="carousel-caption" style="display: flex; align-items: center">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-lg-8 col-md-10">
                  <div class="hero_content text-center">
                    <h3 class="hero_title wow">Bem vindo à Creditall</h3>
                    <p class="wow">A melhor e mais inteligente plataforma financeira do Brasil!</p>
                    <a href="#empresa" class="main-btn wow mb-5 button-slide" style="border-radius: 10px;">Nossa História</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="bg-cover" class="carousel-item single_hero" style="background-image: url('assets/images/recorrencia.png')">
          <div class="carousel-caption" style="display: flex; align-items: center">
            <div class="container">
              <div class="row justify-content-center">
                <div class="icon">
                  <i class="bi bi-credit-card" style="font-size: 35px;padding: 20px; border-radius: 50px; background-color: #bf1b39;"></i>
                </div>
                <div class="col-lg-8 col-md-10">
                  <div class="hero_content text-center">
                    <h2 class="hero_title wow">Recorrência Garantida</h2>
                    <p class="wow">Receba parcelas recorrentes de forma fácil e segura.</p>
                    <a href="/recorrencia-garantida" class="main-btn wow mb-5" style="border-radius: 10px;">Saiba mais</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="bg-cover" class="carousel-item single_hero" style="background-image: url('assets/images/Prancheta2.png')">
          <div class="carousel-caption" style="display: flex; align-items: center"> 
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-lg-8 col-md-10">
                  <div class="hero_content text-center">
                    <div class="icon" data-v-55f1eb6e="" data-v-711fd5ce="">
                      <span class="material-icons" data-v-55f1eb6e="" data-v-711fd5ce="" style="font-size: 35px;padding: 20px; border-radius: 50px; background-color: #bf1b39;">pix</span>
                    </div>
                    <h2 class="hero_title wow">Pix Parcelado Garantido</h2>
                    <p class="wow">Parcelamos suas vendas em até 12 vezes sem entrada, e com 100% de garantia em caso de inadimplência!</p>
                    <a href="/pix-parcelado-garantido" class="main-btn wow mb-5" style="border-radius: 10px;">Saiba mais</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="bg-cover" class="carousel-item single_hero" style="background-image: url('assets/images/Prancheta3.png')">
          <div class="carousel-caption" style="display: flex; align-items: center"> 
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-lg-8 col-md-10">
                  <div class="hero_content text-center">
                    <div style="width: 100%;">
                      <div style="display: flex; width: 100%; justify-content: center;">
                        <div class="icon">
                          <i class="bi bi-upc-scan" style="font-size: 35px;padding: 20px; border-radius: 50px; background-color: #bf1b39;"></i>
                        </div>
                      </div>
                        <h2 class="hero_title wow" style="display: inline;">
                           Boleto Parcelado Garantido
                        </h2>
                    </div>
                    <p class="wow animated" style="visibility: visible;">
                      <font face="Sansation_Bold">
                        Parcelamos suas vendas em até 12 vezes sem entrada, e com 100% de garantia em caso de inadimplência.
                      </font>
                    </p>
                    <a href="/boleto-garantido" class="main-btn wow mb-5 animated" style="border-radius: 10px; visibility: visible;">
                      Saiba mais
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="bg-cover" class="carousel-item single_hero" style="background-image: url('assets/images/Prancheta4.png')">
          <div class="carousel-caption" style="display: flex; align-items: center"> 
            <div class="container">
              <div class="row justify-content-center">
                <div class="icon">
                  <i class="bi bi-credit-card" style="font-size: 35px;padding: 20px; border-radius: 50px; background-color: #bf1b39;"></i>
                </div>
                <div class="col-lg-8 col-md-10">
                  <div class="hero_content text-center">
                    <h2 class="hero_title wow">Cheque Parcelado Garantido</h2>
                    <p class="wow">Tradicional e ideal nas transações de alto valor, e melhor, com 100% de garantia em caso de inadimplência.</p>
                    <a href="/gestao-cheque" class="main-btn wow mb-5" style="border-radius: 10px;">Saiba mais</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="bg-cover" class="carousel-item single_hero" style="background-image: url('assets/images/slide2.png')">
          <div class="carousel-caption" style="display: flex; align-items: center"> 
            <div class="container">
              <div class="row justify-content-center">
                <div class="icon">
                  <i class="bi bi-house" style="font-size: 35px;padding: 20px; border-radius: 50px; background-color: #bf1b39;"></i>
                </div>
                <div class="col-lg-8 col-md-10">
                  <div class="hero_content text-center">
                    <h2 class="hero_title wow">Garantia de Aluguel</h2>
                    <p class="wow">Com o CreditallPay® Aluguel Garantido, você tem 100% de certeza nos recebimentos de seus aluguéis!</p>
                    <a href="/garantia-aluguel" class="main-btn wow mb-5" style="border-radius: 10px;">Saiba mais</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <a class="carousel-control-prev" href="#carousel-example-2" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>

      <a class="carousel-control-next" href="#carousel-example-2" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </section>
</template>

<script>
  export default{
    methods:{
      sistema(){
        window.open('https://www.siscredit.com.br/', '_blank');
      }
    }
  }
</script>


<style scoped>

.carousel-item {
  height: 100vh;
  min-height: 350px;
  width: 100%;
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.btn {
  position: relative;
  display: block;
  color: white;
  font-size: 14px;
  font-family: "montserrat";
  text-decoration: none;
  margin: 30px 0;
  border: 2px solid #bf1b39;
  border-radius: 0px;
  padding: 14px 60px;
  text-transform: uppercase;
  overflow: hidden;
  transition: 1s all ease;
}

.btn::before {
  background: #bf1b39;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  transition: all 0.6s ease;
}

.btn1::before {
  width: 0%;
  height: 100%;
}

.btn1:hover::before {
  width: 100%;
}

.main-btn {
  margin-top: 40px;
}

#servicoSelect {
  font-size: 13px;
  font-weight: 400;
  padding-top: 9px;
  margin-left: 40px;
  color: #fff;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  padding: 10px 0;
  position: relative;
}

.sticky #servicoSelect {
  color: #222;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  color: #fff;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 0;
  top: calc(100% + 30px);
  margin: 0;
  padding: 5px 5px;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul li a {
  min-width: 100%;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  color: #191919;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
  color: #fff;
  background: #bf1b39;
}

.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media screen and (device-aspect-ratio: 40/71) {
  #home {
    width: 100%;
  }
}

@media screen and (device-aspect-ratio: 375/667) {
  #home {
    width: 100%;
  }
}

@media screen and (device-aspect-ratio: 360/640) {
  #home {
    width: 100%;
  }
}

@media screen and (device-aspect-ratio: 375/812) {
  #home {
    width: 100%;
  }
}

@media screen and (device-aspect-ratio: 280/653) {
  #home {
    width: 100%;
  }
}

@media screen and (device-aspect-ratio: 360/760) {
  #home {
    width: 100%;
  }
}

@media screen and (device-aspect-ratio: 360/800) {
  #home {
    width: 100%;
  }
}

@media screen and (device-aspect-ratio: 320/533) {
  #home {
    width: 100%;
  }
}

@media screen and (device-aspect-ratio: 320/480) {
  #home {
    width: 100%;
  }
}

@media screen and (device-aspect-ratio: 240/320) {
  #home {
    width: 100%;
  }
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }
  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}


.header-slide {
  padding: 100px 0;
  background: linear-gradient(135deg, #f8f9fa 0%, #ffffff 100%);
  font-family: 'Roboto', sans-serif;
  text-align: center;
}

.hero_title {
  font-size: 36px;
  font-weight: 700; 
  color: #ffffff;
  margin-bottom: 20px;
}

.header-slide p {
  font-size: 18px; 
  font-weight: 400; 
  color: #ffffff;
  margin-bottom: 20px;
}
 
.main-btn:hover {
  background-color: #a3142e; 
}

</style>
