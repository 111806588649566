<template>
<div>
    <section id="home" class="header_area">
    <div id="header_navbar" class="header_navbar">
      <div class="container d-flex align-items-center justify-content-between" style="width: 100%">
        <div class="row">
          <div class="col-lg-12">
            <nav class="navbar navbar-expand-lg">
              
             


              <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <template v-for="n in 3" :key="n">
                  <span class="toggler-icon"></span>
                </template>
              </button>

              <div class="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent" style="margin-top:1px;">
                
                <a class="navbar-brand" href="/">
                  <img id="logo" width="150px" style="margin-top:1px;" src="assets/images/logo_creditall_inverso.png">
                </a>
             
                <ul id="navbar-toggler" class="navbar-nav ml-auto">
                  <li class="nav-item"><a style="font-size: 13px; margin-top:10px;" class="page-scroll" href="#inicio"><div data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">Início</div></a></li>
                  <li class="nav-item dropdown"><a style="font-size: 13px; margin-top:10px;" class="page-scroll" href="#comofunciona"><div data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">Como Funciona<i class="bi bi-chevron-down position-absolute start-100"></i></div></a>
                   <ul>
                      <li><a style="font-size: 12px; ;white-space: nowrap;" class="page-scroll" href="#comofuncionaparceiro"><div data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">Para Parceiros</div></a></li>
                      <li><a style="font-size: 12px; ;white-space: nowrap;" class="page-scroll" href="#comofuncionacliente"><div data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">Para Clientes</div></a></li>
                    </ul></li>
                  <li class="nav-item"><a style="font-size: 13px; margin-top:10px;" class="page-scroll" href="#beneficios"><div data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">Benefícios</div></a></li>
                  <li class="nav-item"><a style="font-size: 13px; margin-top:10px;" class="page-scroll" href="#vantagens"><div data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">Vantagens</div></a></li>
                  <li class="nav-item"><a style="font-size: 13px; margin-top:10px;" class="page-scroll" href="#parceiros"><div data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">Parceiros</div></a></li>
                  <li class="nav-item"><a style="font-size: 13px; margin-top:10px;" class="page-scroll" href="#queroser"><div data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">Quero ser Cliente</div></a></li>
                  <li class="nav-item">
                    <div class="d-grid">
                        <a href="https://siscredit.com.br" target="_blank" class="btn btn-primary" style="font-family: poppins, sans-serif; font-size: 12px; margin-top: 10px; font-weight: 400; color: rgb(255, 255, 255); background: rgb(191, 27, 57); padding: 9px; right: 1rem; left: 0px; white-space: nowrap; margin-bottom: 15px; border-radius: 8px;">
                        Acessar o sistema
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  <section id="hero" class="d-flex flex-column justify-content-center">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-8">
          <h4 class="text-white">Venda contínua, crescimento garantido</h4>
              <h3><img href="/" width="500px" style="margin-top: 10px" src="assets/images/creditall_recorrencia.png"/></h3>
           
        </div>
      </div>
    </div>
  </section>
</div>

</template>

<script>
    export default{
      methods:{
        sistema(){
          window.open('https://www.siscredit.com.br/', '_blank');
        }
      }
    }
</script>

<style scoped>
 
#hero {
  width: 100%;
  height: 100vh;
  background: url("../../public/assets/images/recorrencia.png") top center;
  background-size: cover;
  background-color: rgba(43,42,61,.7);
  position: relative;
}


@media only screen and (min-width:992px) and (max-width:1199px){
    #hero{
      width: 100%;
      height: 100vh;
    }
}

@media only screen and (min-width:768px) and (max-width:991px){
    #hero{
      width: 100%;
      height: 100vh;
    }
}

@media(max-width:767px){
    #hero{
      width: 100%;
      height: 100vh;
    }
}

@media only screen and (min-width:576px) and (max-width:767px){
    #hero{
      width: 100%;
      height: 100vh;
    }
}

@media only screen and (min-width:440) and (max-width:576px){
    #hero{
      width: 100%;
      height: 60vh;
    }
}

#hero:before {
  content: "";
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
#hero .container {
  padding-top: 72px;
  position: relative;
  text-align: center;
}
#hero h1 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: #fff;
}
#hero h2 {
  color: #eee;
  margin-bottom: 40px;
  font-size: 24px;
}
#hero .play-btn {
  width: 94px;
  height: 94px;
  margin: 0 auto;
  background: radial-gradient(#990000 50%, rgba(153, 0, 0, 0.4) 52%);
  border-radius: 50%;
  display: block;
  overflow: hidden;
  position: relative;
}
#hero .play-btn::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
#hero .play-btn::before {
  content: "";
  position: absolute;
  width: 120px;
  height: 120px;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation: pulsate-btn 3s;
  animation: pulsate-btn 3s;
  -webkit-animation-direction: forwards;
  animation-direction: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: steps;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid rgba(153, 0, 0, 0.7);
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}
#hero .play-btn:hover::after {
  border-left: 15px solid #990000;
  transform: scale(20);
}
#hero .play-btn:hover::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 200;
  -webkit-animation: none;
  animation: none;
  border-radius: 0;
}

#hero .img {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 200;
  -webkit-animation: none;
  animation: none;
  border-radius: 0;
}


.btn {
  position: relative;
  display: block;
  color: white;
  font-size: 14px;
  font-family: "montserrat";
  text-decoration: none;
  margin: 30px 0;
  border: 2px solid #BF1B39;
  padding: 14px 60px;
  text-transform: uppercase;
  overflow: hidden;
  transition: 1s all ease;
}
.btn::before {
  background: #BF1B39;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  transition: all 0.6s ease;
}
.btn1::before {
  width: 0%;
  height: 100%;
}
.btn1:hover::before {
  width: 100%;
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}
@media (max-width: 768px) {
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }
  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
}

@-webkit-keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

@keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}


#servicoSelect {
  font-size: 16px;
  font-weight: 400;
  padding-top: 9px;
  margin-left: 40px;
  color: #fff;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  padding: 10 px 0;
  position: relative;
}
.sticky_ga #servicoSelect {
  color: #222;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255 em;
  color: #fff;
  vertical-align: 0.255 em;
  content: "";
  border-top: 0.3 em solid;
  border-right: 0.3 em solid transparent;
  border-bottom: 0;
  border-left: 0.3 em solid transparent;
}
.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 0;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}
.navbar .dropdown ul li {
  min-width: 200px;
}
.navbar .dropdown ul li a {
  min-width: 100%;
}
.navbar .dropdown ul a {
  padding: 10px 20px;
  color: #191919;
}
.navbar .dropdown ul a i {
  font-size: 12px;
}
.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
  color: #fff;
  background: #cc1616;
}
.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}
.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}
.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media screen and (device-aspect-ratio: 40/71) {
  #home {
    width: 100%;
  }
}
@media screen and (device-aspect-ratio: 375/667) {
  #home {
    width: 100%;
  }
}
@media screen and (device-aspect-ratio: 360/640) {
  #home {
    width: 100%;
  }
}
@media screen and (device-aspect-ratio: 375/812) {
  #home {
    width: 100%;
  }
}
@media screen and (device-aspect-ratio: 280/653) {
  #home {
    width: 100%;
  }
}
@media screen and (device-aspect-ratio: 360/760) {
  #home {
    width: 100%;
  }
}
@media screen and (device-aspect-ratio: 360/800) {
  #home {
    width: 100%;
  }
}
@media screen and (device-aspect-ratio: 320/533) {
  #home {
    width: 100%;
  }
}
@media screen and (device-aspect-ratio: 320/480) {
  #home {
    width: 100%;
  }
}
@media screen and (device-aspect-ratio: 240/320) {
  #home {
    width: 100%;
  }
}


.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}
.navbar-mobile .dropdown ul li {
  min-width: 200px;
}
.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}
.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}
.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > a {
  color: #cc1616;
}
.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}
div.row.justify-content-center {
  margin: 0;
}

@media (max-width: 1366px) {
  .navbar-mobile .dropdown .dropdown ul {
    left: -90%;
  }
  .navbar-mobile .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

</style>
