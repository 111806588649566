<script setup>
import * as CookieConsent from 'vanilla-cookieconsent'; // Importação correta
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import { onMounted } from 'vue';

onMounted(async () => {
  try {
    await CookieConsent.run({
      disablePageInteraction: true,
      guiOptions: {
        consentModal: {
          layout: 'box',
          position: 'bottom left',
          flipButtons: true,
        },
        preferencesModal: {
          layout: 'box',
          position: 'right',
        },
      },
      categories: {
        necessary: {
          enabled: true,
          readOnly: true,
        },
        analytics: {
          enabled: false,
          autoClear: { cookies: ['_ga', '_gid'] },
        },
        marketing: {
          enabled: false,
          autoClear: { cookies: ['_fbp'] },
        },
      },
      language: {
        default: 'pt',
        translations: {
          pt: {
            consentModal: {
              title: 'Este site usa cookies',
              description: 'Utilizamos cookies para melhorar sua experiência.',
              acceptAllBtn: 'Aceitar tudo',
              acceptNecessaryBtn: 'Somente necessários',
              showPreferencesBtn: 'Gerenciar preferências',
              closeIconLabel: 'Fechar',
            },
            preferencesModal: {
              title: 'Gerenciar preferências de cookies',
              saveBtn: 'Salvar configurações',
              acceptAllBtn: 'Aceitar tudo',
              closeIconLabel: 'Fechar',
              sections: [
                {
                  title: 'Cookies necessários',
                  description: 'Cookies necessários para o funcionamento do site.',
                  cookieTable: {
                    headers: {
                      name: 'Necessários',
                      description: '',
                      duration: '',
                    },
                    body: [
                      {
                        name: 'Autenticação',
                        description: 'Cookie que armazena os dados de login e sub-login para manter a conexão ativa do usuário, evitando a necessidade de novo login a cada página visitada.',
                        duration: '6(seis) horas',
                      },
                      {
                        name: 'Cloudflare',
                        description: 'Coleta da localização para validação de região/país',
                        duration: 'Até encerramento da sessão',
                      },
                    ],
                  },
                  linkedCategory: 'necessary',
                },
                {
                  title: 'Cookies de análise',
                  description: 'Cookies que nos ajudam a entender como você usa o site.',
                  cookieTable: {
                    headers: {
                      name: 'Name',
                      description: '',
                      duration: '',
                    },
                    body: [
                      {
                        name: 'Google Analytic',
                        description: 'Uma das soluções de análise mais difundidas e confiáveis da Web, para nos ajudar a entender como você usa o site e como podemos melhorar sua experiência.',
                        duration: 'Até o encerramento da sessão',
                      },
                    ],
                  },
                  linkedCategory: 'analytics',
                },
              ],
            },
          },
        },
      },
    });

    console.log('CookieConsent configurado com sucesso!');
  } catch (error) {
    console.error('Erro ao inicializar o CookieConsent:', error);
  }
});
</script>
