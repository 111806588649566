import { createRouter, createWebHistory } from 'vue-router';
import AnaliseCredito from '../pages/AnaliseCredito.vue';
import BoletoGarantidoo from '../pages/BoletoGarantido.vue';
import BoletoGarantido from '../pages/BoletoGarantidoWeb.vue';
import CadastroConsumidor from '../pages/CadastroConsumidor.vue';
import CadastroGA from '../pages/CadastroGA.vue';
import CadastroPPG from '../pages/CadastroPPG.vue';
import CartaoCompra from '../pages/CartaoCompra.vue';
import Dev from '../pages/Dev.vue';
import EmitirGuia from '../pages/EmitirGuia.vue';
import Empresa from '../pages/Empresa.vue';
import Boletos from '../pages/FaturaHistorico.vue';
import Funcionario from '../pages/Funcionario.vue';
import GarantiaAluguel from '../pages/GarantiaAluguel.vue';
import GarantiaCheque from '../pages/GarantiaCheque.vue';
import GarantiaCrediario from '../pages/GarantiaCrediario.vue';
import GestaoCheque from '../pages/GestaoCheque.vue';
import GestaoCompras from '../pages/GestaoCompras.vue';
import GestaoCrediario from '../pages/GestaoCrediario.vue';
import LGPD from '../pages/LGPD.vue';
import Licenciado from '../pages/Licenciado.vue';
import MarcarReuniao from '../pages/MarcarReuniao.vue';
import PixParcelado from '../pages/PixParcelado.vue';
import PoliticasPrivacidades from '../pages/PoliticasPrivacidades.vue';
import QueroSerCliente from '../pages/QueroSerCliente.vue';
import Recorrencia from '../pages/Recorrencia.vue';
import SobreGA from '../pages/SobreGA.vue';
import SobrePPG from '../pages/SobrePPG.vue';
import SouConsumidor from '../pages/SouConsumidor.vue';
import SouLojista from '../pages/SouLojista.vue';
import TrabalheConosco from '../pages/TrabalheConosco.vue';
import Home from '../views/Home.vue';
import PathNotFound from '../views/PathNotFound.vue';

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/boleto-garantido',
        name: 'BoletoGarantido',
        component: BoletoGarantido,
    },
    {
        path: '/boleto-garantidoo',
        name: 'BoletoGarantidoo',
        component: BoletoGarantidoo
    },
    {
        path: '/cartao-compras',
        name: 'CartaoCompra',
        component: CartaoCompra
    },
    {
        path: '/garantia-crediario',
        name: 'GarantiaCrediario',
        component: GarantiaCrediario
    },
    {
        path: '/garantia-cheque',
        name: 'GarantiaCheque',
        component: GarantiaCheque
    },
    {
        path: '/gestao-cheque',
        name: 'GestaoCheque',
        component: GestaoCheque
    },
    {
        path: '/gestao-crediario',
        name: 'GestaoCrediario',
        component: GestaoCrediario
    },
    {
        path: '/gestao-compras',
        name: 'GestaoCompras',
        component: GestaoCompras
    },
    {
        path: '/sou-lojista',
        name: 'SouLojista',
        component: SouLojista
    },
    {
        path: '/marcar-reuniao',
        name: 'MarcarReuniao',
        component: MarcarReuniao
    },
    {
        path: '/sou-consumidor',
        name: 'SouConsumidor',
        component: SouConsumidor
    },
    {
        path: '/analise-credito',
        name: 'AnaliseCredito',
        component: AnaliseCredito
    },
    {
        path: '/emitir-guia',
        name: 'EmitirGuia',
        component: EmitirGuia
    },
    {
        path: '/boleto',
        name: 'Boletos',
        component: Boletos
    },
    {
        path: '/fatura',
        name: 'Boletos',
        component: Boletos
    },
    {
        path: '/lgpd',
        name: 'LGPD',
        component: LGPD,
    },
    {
        path: '/politica-privacidade',
        name: 'PoliticasPrivacidades',
        component: PoliticasPrivacidades,
    },
    {
        path: '/quero-ser-cliente',
        name: 'QueroSerCliente',
        component: QueroSerCliente,
    },
    {
        path: '/trabalhe-conosco',
        name: 'TrabalheConosco',
        component: TrabalheConosco,
    },
    {
        path: '/a-empresa',
        name: 'Empresa',
        component: Empresa,
    },
    {
        path: '/garantia-aluguel',
        name: 'GarantiaAluguel',
        component: GarantiaAluguel,
    },
    {
        path: '/desenvolvedores',
        name: 'Dev',
        component: Dev,
    },
    {
        path: '/cadastro-consumidor',
        name: 'CadastroConsumidor',
        component: CadastroConsumidor,
    },
    {
        path: '/cadastro-garantia-aluguel',
        name: 'CadastroGA',
        component: CadastroGA,
    },
    {
        path: '/sobre-garantia-aluguel',
        name: 'SobreGA',
        component: SobreGA,
    },
    {
        path: '/recorrencia-garantida',
        name: 'RecorrenciaGarantida',
        component: Recorrencia,
    },
    {
        path: '/pix-parcelado-garantido',
        name: 'PixParcelado',
        component: PixParcelado,
    },
    {
        path: '/sobre-pix-parcelado-garantido',
        name: 'SobrePPG',
        component: SobrePPG,
    },
    {
        path: '/cadastro-pix-parcelado-garantido',
        name: 'CadastroPPG',
        component: CadastroPPG,
    },
    {
        path: '/licenciado',
        name: 'Licenciado',
        component: Licenciado,
    },
    {
        path: '/funcionario',
        name: 'Funcionario',
        component: Funcionario,
    },

    {
        path: '/recorrencia',
        redirect: '/fatura',
    },
    {
        path: '/produtos-e-servicos/recorrencia-garantida',
        redirect: '/recorrencia-garantida',
    },
    {
        path: '/produtos-e-servicos/recorrencia-garantida',
        redirect: '/recorrencia-garantida'
    },

    // REDIRECIONAMENTO //
    {
        path: '/boletos',
        redirect: '/fatura',
    },
    {
        path: '/boleto',
        redirect: '/fatura',
    },
    {
        path: '/produtos-e-servicos/boleto-garantido',
        redirect: '/boleto-garantido',
    },
    {
        path: '/produtos-e-servicos/boleto-garantido-web',
        redirect: '/boleto-garantido'
    },
    {
        path: '/produtos-e-servicos/cartao-compras',
        redirect: '/cartao-compras'
    },
    {
        path: '/garantia-de-credito/garantia-de-crediario',
        redirect: '/garantia-crediario'
    },
    {
        path: '/garantia-de-credito/garantia-de-cheque',
        redirect: '/garantia-cheque'
    },
    {
        path: '/garantia-de-desempenho/gestao-de-cheque',
        redirect: '/gestao-cheque'
    },
    {
        path: '/garantia-de-desempenho/gestao-de-crediario',
        redirect: '/gestao-crediario'
    },
    {
        path: '/garantia-de-desempenho/gestao-de-compras',
        redirect: '/gestao-compras'
    },



    { path: '/:pathMatch(.*)*', component: PathNotFound },

]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router