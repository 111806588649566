<template>
  <TopBar />

  <section id="breadcrumbs" class="breadcrumbs">
    <div class="container">
      <div class="col-lg-12">
      <ol>
        <li class="text-danger"><a href="/">Início</a></li>
      </ol>
      <h2>Política de Privacidades e Cookies</h2>
      </div>
    </div>
  </section>
  <!-- End Breadcrumbs -->

  <section class="inner-page mt-5">
    <div class="container">
      <div class="col-lg-12">
      <p>
        A Creditall Gestão e Garantia de Crédito EIRELI EPP, pessoa jurídica de direito privado, com sede na Rua 7 de Setembro, nº 223, Centro Norte, em Mandaguaçu-PR, CEP 87.160-039, inscrita no CNPJ sob o nº 11.927.349/0001-90, 
        para melhorar a experiência e zelando pela privacidade dos dados pessoais de seus clientes e parceiros, disponibiliza a presente política de privacidade com a finalidade de esclarecer - 
        com a maior transparência possível - como os seus dados são geridos por nossa plataforma nas fases de 
        a) coleta, b) tratamento, c) armazenamento e d) descarte.
        <br>
        Desde já deixamos claro que quaisquer dúvidas referentes aos seus dados poderão ser enviadas à nossa Encarregada de Proteção de Dados Raquel Cunha, através do e-mail contato@creditall.com.br, que serão respondidas em até 15 dias.  
        <br>
      </p>
      <p>
        <b>DEFINIÇÕES:</b>
        <br>
        Para que você entenda melhor como esta política de privacidade funciona e como seus dados são tratados, faz-se necessário apontarmos as definições de alguns termos:
      </p>
          <div id="accordion">
            <div class="card">
              <div class="card-header" id="headingOne">
                <h5 class="mb-0">
                  <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    Dados Pessoais 
                    <i class="bi bi-chevron-right"></i>
                  </button>
                </h5>
              </div>

              <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                <div class="card-body">
                    <p>
                      Toda informação relacionada à pessoa física identificada ou que possa ser de alguma forma identificável.
                    </p>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingTwo">
                <h5 class="mb-0">
                  <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    Lei Geral de Proteção de Dados 
                    <i class="bi bi-chevron-right"></i>
                  </button>
                </h5>
              </div>
              <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                <div class="card-body">
                    <p>
                        É a Lei 13.709/2018 que visa proteger os dados das pessoas físicas de usos indevidos ou abusivos. 
                        Esta lei está disponível em sua integralidade no site <a href="https://www.planalto.gov.br" target="_blank" rel="noopener noreferrer" style="color: crimson;">https://www.planalto.gov.br</a>.
                    </p>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingThree">
                <h5 class="mb-0">
                  <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    Operador
                    <i class="bi bi-chevron-right"></i>
                  </button>
                </h5>
              </div>
              <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                <div class="card-body">
                    <p>
                      Pessoa jurídica ou física, distinta do controlador, que trata Dados Pessoais de acordo com as instruções do Controlador. São terceirizados contratados pelo Controlador.
                    </p>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingFour">
                <h5 class="mb-0">
                  <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                    Cookies
                    <i class="bi bi-chevron-right"></i>
                  </button>
                </h5>
              </div>
              <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                <div class="card-body">
                    <p>
                      Um cookie é um pequeno fragmento de dados que nosso site armazena em seu navegador e que contém algumas informações sobre você, como sua preferência de idioma ou informações de login. Em uma nova visita, seu navegador nos envia este fragmento de volta para podermos fornecer uma experiência de navegação mais personalizada. Essas informações são usadas principalmente para que o site funcione como você espera e geralmente não o identificam diretamente.
                    </p>
                    <p>
                      Quando você realiza um cadastro no sistema Creditall, nos estabelecimentos conveniados, são coletados os seguintes dados:
                      <br>
                    </p>
                    <b>Dados Coletados:</b>
                    <ul>
                      <li>a) Nome;</li>
                      <li>b) CPF;</li>
                      <li>c) E-mail;</li>
                      <li>d) Telefone e nome de recado;</li>
                      <li>e) Endereço;</li>
                    </ul>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingFive">
                <h5 class="mb-0">
                  <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                    Finalidade
                    <i class="bi bi-chevron-right"></i>
                  </button>
                </h5>
              </div>
              <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                <div class="card-body">
                    <p>
                      Solicitamos informações pessoais apenas quando realmente precisamos delas para lhe fornecer um serviço. Fazemos por meios justos e legais, com o seu conhecimento e, quando necessário, seu consentimento. Também informamos porquê estamos coletando e como será usado.
                      Você é livre para recusar a nossa solicitação de informações pessoais, entendendo que talvez não possamos fornecer alguns dos serviços desejados.
                    </p>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingSix">
                <h5 class="mb-0">
                  <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                    Compartilhamento
                    <i class="bi bi-chevron-right"></i>
                  </button>
                </h5>
              </div>
              <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordion">
                <div class="card-body">
                    <p>
                      Apenas compartilhamos seus dados quando há alguma inadimplência, para a empresa RGS PROCESSADORA DE CREDITO LTDA, inscrita no CNPJ 08.117.188/0001-64, nossa operadora de dados para realização de cobranças.
                    </p>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingSeven">
                <h5 class="mb-0">
                  <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                    Armazenamento
                    <i class="bi bi-chevron-right"></i>
                  </button>
                </h5>
              </div>
              <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordion">
                <div class="card-body">
                    <p>
                      Nosso banco de dados fica armazenado no Brasil (São Paulo-SP) na Uol Host - operadora de dados com os mais elevados níveis de segurança da informação, que pode ser 
                      <b><a href="https://sobreuol.noticias.uol.com.br/normas-de-seguranca-e-privacidade" target="_blank" style="color: crimson;">aqui</a></b> verificados.
                    </p>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingEight">
                <h5 class="mb-0">
                  <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                    Descarte
                    <i class="bi bi-chevron-right"></i>
                  </button>
                </h5>
              </div>
              <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordion">
                <div class="card-body">
                    <p>
                      Apenas retemos as informações coletadas pelo tempo necessário para fornecer o serviço solicitado. Após encerrada nossa relação, os dados ficarão armazenados pelo tempo de prescrição previsto em lei sob a égide da base legal do exercício regular de direitos, após iremos eliminá-los com segurança.
                      Enquanto armazenamos seus dados, os protegemos dentro de meios comercialmente aceitáveis para evitar perdas e roubos, bem como acesso, divulgação, cópia, uso ou modificação não autorizados.
                    </p>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingNine">
                <h5 class="mb-0">
                  <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                    Conheça seus direitos
                    <i class="bi bi-chevron-right"></i>
                  </button>
                </h5>
              </div>
              <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordion">
                <div class="card-body">
                  <p>Você tem os seguintes direitos em relação aos seus dados pessoais:</p>
                  <ul style="list-style-type: disc; padding-left: 20px;">
                    <li><b>Confirmação</b> da existência de tratamento de dados pessoais.</li>
                    <li><b>Acesso</b> aos seus dados pessoais armazenados pela Loja.</li>
                    <li><b>Correção</b> de dados que possam estar incompletos, inexatos ou desatualizados.</li>
                    <li><b>Anonimização, bloqueio ou eliminação</b> de dados considerados desnecessários, excessivos ou tratados em desconformidade com a LGPD.</li>
                    <li><b>Eliminação</b> de dados pessoais tratados com seu consentimento para fins de marketing.</li>
                    <li><b>Portabilidade</b> dos dados a outro fornecedor de serviços.</li>
                    <li>Informação sobre a possibilidade de <b>não consentir</b> o tratamento e suas consequências.</li>
                    <li>Informação sobre <b>decisões automatizadas</b>, incluindo critérios e procedimentos adotados.</li>
                  </ul>
                  <p>Para exercer seus direitos, entre em contato pelo e-mail <a href="mailto:contato@creditall.com.br">contato@creditall.com.br</a>. Para garantir sua segurança, poderemos solicitar alguns dados para confirmação de identidade, que serão excluídos assim que a solicitação for concluída.</p>
                  <p>O uso continuado de nosso site será considerado como aceitação de nossas práticas em torno de privacidade e informações pessoais. Se você tiver alguma dúvida sobre como lidamos com dados do usuário e informações pessoais, entre em contato conosco.</p>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingTen">
                <h5 class="mb-0">
                  <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                    Política de Cookies
                    <i class="bi bi-chevron-right"></i>
                  </button>
                </h5>
              </div>
              <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-parent="#accordion">
                <div class="card-body">
                    <p><b>O que são cookies?</b></p>
                    <p>
                      Como é prática comum em quase todos os sites profissionais, este site usa cookies, que são pequenos arquivos baixados no seu computador, para melhorar sua experiência. 
                      Esta página descreve quais informações eles coletam, como as usamos e por que às vezes precisamos armazenar esses cookies. Também compartilharemos como você pode impedir que esses cookies sejam armazenados, no entanto, isso pode fazer o downgrade ou ‘quebrar’ certos elementos da funcionalidade do site.
                    </p>
                    <br>
                    <p><b>Como usamos os cookies?</b></p>
                    <p>
                      Utilizamos cookies por vários motivos, detalhados abaixo. Infelizmente, na maioria dos casos, não existem opções padrão do setor para desativar os cookies sem desativar completamente a funcionalidade e os recursos que eles adicionam a este site. É recomendável que você deixe todos os cookies se não tiver certeza se precisa ou não deles, caso sejam usados para fornecer um serviço que você usa.
                    </p>
                    <br>
                    <p><b>Desativar cookies</b></p>
                    <p>Automaticamente deixamos todos os cookies não necessários e que dependem do seu consentimento (opt-in) desativados.</p>
                    <p>
                      Dessa forma, você pode configurá-los ajustando as configurações do seu navegador (conforme a finalidade de cada um, descrita em nosso cardápio). Esteja ciente de que a desativação de cookies afetará a funcionalidade deste e de muitos outros sites que você visita. A desativação de cookies geralmente resultará na desativação de determinadas funcionalidades e recursos deste site. Portanto, é recomendável que você ative-os.
                    </p>
                    <br>
                    <p><b>Cookies que definimos</b></p>
                    <ul>
                      <li>
                        <b>Cookies relacionados ao login</b>
                        <p>Utilizamos cookies quando você está logado, para que possamos lembrar dessa ação. Ele coleta seus dados de login e sub-login com a finalidade de manter ativa a conexão do usuário. Isso evita que você precise fazer login sempre que visitar uma nova página. Esses cookies são normalmente removidos ou limpos quando você efetua logout para garantir que você possa acessar apenas a recursos e áreas restritas ao efetuar login.</p>
                      </li>
                      <li>
                        <b>Cookies de Terceiros:</b>
                        <p>
                          Em alguns casos especiais, também usamos cookies fornecidos por terceiros confiáveis. A seção a seguir detalha quais cookies de terceiros você pode encontrar através deste site.
                        </p>
                        <p>
                          Este site usa o Google Analytics, que é uma das soluções de análise mais difundidas e confiáveis da Web, para nos ajudar a entender como você usa o site e como podemos melhorar sua experiência. Esses cookies podem rastrear itens como quanto tempo você gasta no site e as páginas visitadas, para que possamos continuar produzindo conteúdo atraente. Para mais informações sobre cookies do Google Analytics, consulte a página oficial do Google Analytics.
                          Também utilizamos o cookie Cloudflare, que coleta dados de localização do usuário (IP), apenas para validação de região/país, a fim de evitar ataques DDoS.
                        </p>
                        <p>
                          Por fim, também fazemos uso do UNPKG, que não coleta qualquer dado pessoal dos usuários.
                        </p>
                      </li>
                    </ul>
                </div>
              </div>
            </div>
       
          </div>
          <br><br>
          <p>
            <b>ATUALIZAÇÕES DESTA POLÍTICA</b>
            <br>
            Caso este documento seja modificado, o novo texto será publicado nesta mesma plataforma.
            <br>
          </p>
          <p style="display: flex; justify-content: flex-end;">Mandaguaçu-PR, 28 de março de 2025.   </p>
      </div>
    </div>
<div style="margin-top: 75px"></div>

  </section>
  <Footer />
</template>

<script>
import Footer from "../components/Footer.vue";
import TopBar from "../components/TopBar.vue";

export default {
  name: "PoliticasPrivacidades",
  components: {
    TopBar,
    Footer,
  },
};
</script>

<style scoped>
.btn-link {
    font-weight: 400;
    color: #444444;
    text-decoration:none;
}

.breadcrumbs {
  padding: 45px 0;
  margin-top: 4rem;
  background: #efefef;
}
@media only screen and (max-width: 320px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

.breadcrumbs h2 {
  font-size: 26px;
  font-weight: 600;
}
.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 14px;
}
.breadcrumbs ol li + li {
  padding-left: 10px;
}
.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #333333;
  content: "/";
}

.accordion-button:not(.collapsed) {
  color: #444444;
  background-color: #e4e4e4;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
</style>
